.App {
  text-align: center;
  background-color: #55b9f3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-neumorphism {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  height: 390px;
  width: 350px;
  border-radius: 50px;
  background: #ca38ff;
  box-shadow: 20px 20px 60px #ac30d9, -20px -20px 60px #e840ff;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.App-blessing {
  font-size: x-large;
  color: #f5e038;
}

.App-label {
  font-size: larger;
}

.App-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-left: 33px;
}

.App-button {
  margin-top: 20px;
  margin-left: 100px;
}

/* From cssbuttons.io by @alexmaracinaru */
button {
  cursor: pointer;
  font-weight: 700;
  font-family: Helvetica, "sans-serif";
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 100px;
  background: #f5e038;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
}

button:hover {
  background: #efeb00;
}

button > svg {
  width: 30px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

button:hover svg {
  transform: translateX(5px);
}

button:active {
  transform: scale(0.85);
}
/* end */

.App-cake {
  height: 30vh;
}

.App-voice {
  margin-top: 20px;
  color: #fce100;
  font-size: large;
}
